//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      default: ''
    },
    next: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      newVal: '',
      loading: false,
      typeEmit: {
        pre: 'pre',
        //上一页
        next: 'next',
        //下一页
        focus: 'focus',
        //获取焦点
        pageNo: 1,
        //页码
        pageSize: 10,
        //每页条数
        total: 0 //总条数

      }
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        this.newVal = val;
      },
      immediate: true
    },
    newVal: {
      handler: function handler(val) {
        this.$emit('change', val);
      },
      immediate: true
    }
  },
  methods: {
    handleChange: function handleChange(e) {
      this.$emit('change', e);
    },
    selectNextFocus: function selectNextFocus(key) {
      var _this = this;

      if (!this.next) return;
      if (this.loading) return;
      if (this.typeEmit.pageNo < 1) this.typeEmit.pageNo = 1;

      if (key === 'pre') {
        if (this.typeEmit.pageNo == 1) {
          this.$msg.warning('已经是第一页了');
          return;
        }

        this.typeEmit.pageNo--;
      } else if (key === 'next') {
        var total = this.typeEmit.pageNo * this.typeEmit.pageSize;

        if (this.typeEmit.total <= total) {
          this.$msg.warning('没有更多数据了');
          return;
        }

        this.typeEmit.pageNo++;
      }

      var params = {
        pageNo: this.typeEmit.pageNo,
        pageSize: this.typeEmit.pageSize,
        key: key
      };
      this.loading = true;
      this.$emit('next', params, function (page) {
        _this.typeEmit.total = 35;
        _this.loading = false;
      });
    }
  }
};