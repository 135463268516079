var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "d2-card",
        [
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "#",
                "on-progress": _vm.uploadVideoProcess,
                "on-success": _vm.handleVideoSuccess,
                "before-upload": _vm.beforeUploadVideo,
                "show-file-list": false,
                "http-request": _vm.httpRequest
              }
            },
            [
              _vm.videoForm.guideVideo != "" && !_vm.videoFlag
                ? _c(
                    "video",
                    {
                      staticClass: "avatar video-avatar",
                      attrs: {
                        src: _vm.videoForm.guideVideo,
                        controls: "controls"
                      }
                    },
                    [_vm._v(" 您的浏览器不支持视频播放 ")]
                  )
                : _vm.videoForm.guideVideo == "" && !_vm.videoFlag
                ? _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
                : _vm._e(),
              _vm.videoFlag == true
                ? _c("el-progress", {
                    staticStyle: { "margin-top": "7px" },
                    attrs: {
                      type: "circle",
                      percentage: _vm.videoUploadPercent
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticStyle: { height: "20px" } })
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-s-promotion" },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }