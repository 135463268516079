var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-pagination",
    _vm._b(
      {
        attrs: {
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
          "current-page": _vm.currentPage,
          "page-size": _vm.pageSize,
          total: _vm.total,
          "page-sizes": [10, 20, 30, 40, 50, 100]
        },
        on: {
          "update:currentPage": function($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function($event) {
            _vm.currentPage = $event
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      },
      "el-pagination",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }