var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { ref: "editor" }),
      _c("base-file", {
        staticClass: "quill-upload",
        class: _vm.uuidQuill,
        attrs: { isImg: "", accept: 1, limit: 99999 },
        on: { success: _vm.successUpload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }