var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "mask",
          class:
            _vm.mask == "true" || _vm.mask == true ? "mask-show" : "mask-show",
          on: {
            click: _vm.Mclose,
            touchmove: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.preventTouchMove($event)
            }
          }
        },
        [
          _c("div", { staticClass: "loading16" }),
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.text))])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }