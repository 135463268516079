var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("headerComp"),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c("userComp"),
              _c("d2-card", [
                _c("div", { staticClass: "d2-p-20" }, [
                  _c("img", {
                    staticStyle: { "max-height": "444px" },
                    attrs: { src: _vm.bgImg, alt: "" }
                  })
                ])
              ])
            ],
            1
          ),
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [_c("dataComp")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }