import "core-js/modules/es.number.constructor";
import _objectSpread from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
// https://github.com/inorganik/CountUp.js
import { CountUp } from 'countup.js';
export default {
  name: 'd2-count-up',
  props: {
    start: {
      type: [Number, String],
      default: 0
    },
    end: {
      type: [Number, String],
      default: 0
    },
    decimals: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 2
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    callback: {
      type: Function
    }
  },
  data: function data() {
    return {
      c: null
    };
  },
  watch: {
    end: function end(value) {
      if (this.c && this.c.update) this.c.update(value);
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      if (!this.c) {
        this.c = new CountUp(this.$el, this.end, _objectSpread({
          startVal: this.start,
          decimalPlaces: this.decimals,
          duration: this.duration
        }, this.options));
        this.c.start(function () {
          if (_this.callback) _this.callback(_this.c);
        });
      }
    }
  }
};