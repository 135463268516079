import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
export default {
  name: 'base-submit',
  props: {
    form: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    rules: {
      type: Object
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    formRef: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      formData: {}
    };
  },
  watch: {
    form: {
      deep: true,
      handler: function handler(val) {
        this.formData = val;
      }
    }
  },
  methods: {
    submitForm: function submitForm(refName) {
      var _this = this;

      return new Promise(function (r, j) {
        _this.$refs[refName].validate(function (valid) {
          if (valid) return r();else {
            _this.$msg.error('表单信息验证失败');

            return false;
          }
        });
      });
    },
    resetFields: function resetFields(refName) {
      this.$refs[refName].resetFields();
    }
  }
};