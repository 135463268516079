import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import Vue from 'vue';
import { cityMap, districtMap } from '@/const/cityMap';
import { citys } from '@/const/citys';
/** 从列表中选出传入条目的对应item */

function ftGetCityByCode(value) {
  var city = cityMap[value];
  var province = '';
  citys.forEach(function (item, index) {
    item.children.forEach(function (itt, idx) {
      if (itt.label == city) {
        province = item.label;
      }
    });
  });
  return province + cityMap[value];
}

Vue.filter('ftGetCityByCode', ftGetCityByCode); //映射省市区

function ftGetDistrictCode(value) {
  var district = districtMap[value];
  return district;
}

Vue.filter('ftGetDistrictCode', ftGetDistrictCode);
export { ftGetCityByCode };