//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'range-datetime',
  props: {
    min: {
      default: ''
    },
    max: {
      default: ''
    }
  },
  data: function data() {
    return {
      value: ''
    };
  },
  watch: {
    value: {
      deep: true,
      handler: function handler() {
        this.$emit('update:min', this.value[0]);
        this.$emit('update:max', this.value[1]);
      }
    }
  }
};