var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              class: { uploadImg: _vm.fileObjListLength >= _vm.limit },
              attrs: {
                drag: _vm.isDrag,
                "list-type": _vm.listType,
                data: _vm.uploadData,
                action: _vm.action,
                multiple: _vm.multiple,
                "file-list": _vm.fileList,
                limit: _vm.limit,
                accept: _vm.acceptActive,
                "on-exceed": _vm.handleExceed,
                "on-success": _vm.handleSuccess,
                "on-remove": _vm.handleRemove,
                "on-preview": _vm.preview,
                "before-upload": _vm.beforeUpload,
                "http-request": _vm.httpRequest
              }
            },
            [
              _vm.isDrag
                ? _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")])
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [
                  _vm.tips
                    ? _c(
                        "el-alert",
                        { attrs: { type: "warning", closable: false } },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.tips) }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("i", {
                staticClass: "el-icon-plus",
                attrs: { slot: "default" },
                slot: "default"
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }