var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "d2-card",
        [
          _c("d2-quill-text", {
            model: {
              value: _vm.form.userAgreement,
              callback: function($$v) {
                _vm.$set(_vm.form, "userAgreement", $$v)
              },
              expression: "form.userAgreement"
            }
          }),
          _c("div", { staticStyle: { height: "20px" } })
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-s-promotion" },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }