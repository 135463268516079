export default {
  title: '条码管理',
  sort: 2,
  path: '/work-users',
  icon: 'bookmark' // children: [
  //   { path: '/adminApplet-users', title: '用户管理', iconSvg: 'd2-power' },
  //   // { path: '/adminApplet-rule', title: '用户角色', iconSvg: 'd2-power' },
  //   // { path: '/authen-set', title: '设置审核方式', icon: 'cogs' },
  // ],

};