var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.selects, function(_, index) {
      return _c("base-select", {
        key: index,
        ref: "selection" + index,
        refInFor: true,
        staticStyle: { "margin-right": "10px" },
        attrs: { options: _vm.selectOptions[index] },
        on: {
          change: function($event) {
            return _vm.handleChange(index)
          }
        },
        model: {
          value: _vm.selectValues[index],
          callback: function($$v) {
            _vm.$set(_vm.selectValues, index, $$v)
          },
          expression: "selectValues[index]"
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }