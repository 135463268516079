import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { assign, map } from 'lodash';
import faker from 'faker/locale/zh_CN';
import { service, request, serviceForMock, requestForMock, mock } from './service';
import * as tools from './tools';

var files = require.context('./modules', true, /\.api\.js$/);

var generators = files.keys().map(function (key) {
  return files(key).default;
});
export default assign.apply(void 0, [{}].concat(_toConsumableArray(map(generators, function (generator) {
  return generator({
    service: service,
    request: request,
    serviceForMock: serviceForMock,
    requestForMock: requestForMock,
    mock: mock,
    faker: faker,
    tools: tools
  });
}))));