import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    src: {
      type: String,
      // default: 'https://pica.zhimg.com/v2-379251a562618b581c2798a77b689ae0_1440w.jpg',
      default: ''
    },
    width: {
      type: Number,
      default: 100
    }
  },
  data: function data() {
    return {};
  }
};