var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-layer" },
    [
      _c(
        "el-dialog",
        _vm._b(
          {
            directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
            attrs: {
              title: _vm.titleLabel,
              visible: _vm.visible,
              width: _vm.width,
              "before-close": _vm.closeDialog,
              "close-on-click-modal": false,
              top: _vm.top
            },
            on: {
              "update:visible": function($event) {
                _vm.visible = $event
              },
              close: _vm.onChancel
            }
          },
          "el-dialog",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "transition",
            { attrs: { name: "fade-transverse" } },
            [
              _vm.visible
                ? _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": _vm.labelWidth
                      }
                    },
                    [_vm._t("default")],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm._t("footer", [
                _vm.buttons.includes("cancel")
                  ? _c("el-button", { on: { click: _vm.onChancel1 } }, [
                      _vm._v(" 取 消 ")
                    ])
                  : _vm._e(),
                _vm.buttons.includes("confirm")
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loadBtn },
                        on: { click: _vm.onConfirm }
                      },
                      [_vm._v(" 确 定 ")]
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }