var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { placement: "bottom" }, on: { command: _vm.onChangeLocale } },
    [
      _c(
        "el-button",
        { staticClass: "d2-mr btn-text can-hover", attrs: { type: "text" } },
        [
          _c("d2-icon", {
            staticStyle: { "font-size": "16px" },
            attrs: { name: "language" }
          })
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.$languages, function(language) {
          return _c(
            "el-dropdown-item",
            { key: language.value, attrs: { command: language.value } },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: {
                  name:
                    _vm.$i18n.locale === language.value
                      ? "dot-circle-o"
                      : "circle-o"
                }
              }),
              _vm._v(" " + _vm._s(language.label) + " ")
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }