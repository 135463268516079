var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-layer" },
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          ref: "dialog",
          attrs: {
            visible: _vm.isShow,
            title: _vm.title,
            width: _vm.width,
            "close-on-click-modal": false,
            top: _vm.top
          },
          on: {
            "update:visible": function($event) {
              _vm.isShow = $event
            },
            close: _vm.onChancel
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _vm.buttons.includes("cancel")
                    ? _c("el-button", { on: { click: _vm.onChancel } }, [
                        _vm._v(" 取消 ")
                      ])
                    : _vm._e(),
                  _vm.buttons.includes("confirm")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loadBtn },
                          on: { click: _vm.confirm }
                        },
                        [_vm._v(" 确定 ")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade-transverse" } },
            [_vm.visible ? _vm._t("default") : _vm._e()],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }