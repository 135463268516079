//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Dashboard',
  data: function data() {
    return {
      chartData: {
        columns: ['日期', '访问用户'],
        rows: [{
          日期: '已实名',
          访问用户: _.random(3792)
        }, {
          日期: '未实名',
          访问用户: _.random(3792)
        }]
      },
      chartSettings: {
        coordinate: {
          radius: 0.8
        },
        radius: [50, 60],
        offsetY: 80,
        label: {
          show: false,
          position: 'center'
        }
      },
      chartExtend: {
        legend: {
          bottom: '5%',
          left: 'center'
        }
      }
    };
  }
};