import "core-js/modules/es.array.includes";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.string.includes";
//校验
var check = {
  had: function had(key, keys) {
    if (!key && key !== 0) return false;
    if (!keys) return false;
    if (!Array.isArray(keys)) return false;
    if (!isNaN(key)) return keys.includes(Number(key));
    return keys.includes(key);
  },
  //不为空或者大于等于0
  isNotEmpty: function isNotEmpty(val) {
    return val != null && val >= 0;
  },
  //对象存在并且不为空
  isNotEmptyObject: function isNotEmptyObject(val) {
    return val != null && val != '';
  },
  //数组存在并且不为空
  isNotEmptyArray: function isNotEmptyArray(val) {
    return val != null && val.length > 0;
  },
  //对象存在并且值不为空或者大于等于0
  isEmptyObj: function isEmptyObj(val, key) {
    return this.isNotEmptyObject(val) && this.isNotEmpty(val[key]);
  }
};
export default check;