var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-card",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6, offset: 0 } }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "red" }, [
                _c("img", { attrs: { src: require("../../image/order.svg") } })
              ]),
              _c("div", { staticClass: "header_box" }, [
                _c("span", [_vm._v("1000")]),
                _c("span", [_vm._v("系统销售总数")])
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 6, offset: 0 } }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "purple" }, [
                _c("img", { attrs: { src: require("../../image/tubiao.svg") } })
              ]),
              _c("div", { staticClass: "header_box" }, [
                _c("span", [_vm._v("1000")]),
                _c("span", [_vm._v("当日系统销售")])
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 6, offset: 0 } }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "green" }, [
                _c("img", { attrs: { src: require("../../image/pirk.svg") } })
              ]),
              _c("div", { staticClass: "header_box" }, [
                _c("span", [_vm._v("1000")]),
                _c("span", [_vm._v("市场订单总数")])
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 6, offset: 0 } }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "orange" }, [
                _c("img", { attrs: { src: require("../../image/jinri.svg") } })
              ]),
              _c("div", { staticClass: "header_box" }, [
                _c("span", [_vm._v("1000")]),
                _c("span", [_vm._v("当日市场订单")])
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }