import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
export default {
  name: 'base-number',
  props: {
    type: {
      default: 'number'
    },
    min: {
      default: ''
    },
    max: {
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  data: function data() {
    return {
      value: ''
    };
  },
  methods: {
    changeInt: function changeInt(val) {
      var item = val.split('.');
      this.value = +item[0];
    }
  },
  watch: {
    min: function min(val) {
      this.valueStart = val;
    },
    max: function max(val) {
      this.valueEnd = val;
    },
    value: {
      deep: true,
      handler: function handler() {
        this.$emit('update', this.value);
      }
    }
  },
  mounted: function mounted() {}
};