export default {
  props: {
    jsx: {
      type: Object
    }
  },
  render: function render(createElement) {
    return this.jsx;
  },
  name: 'jsx-render'
};