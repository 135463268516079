var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户昵称" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户邮箱" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户邮箱" },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户ID" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户ID" },
                model: {
                  value: _vm.form.userNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userNo", $$v)
                  },
                  expression: "form.userNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入手机号码" },
                model: {
                  value: _vm.form.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phoneNumber", $$v)
                  },
                  expression: "form.phoneNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "反馈时间段" } },
            [
              _c("range-date", {
                attrs: { min: _vm.form.fromTime, max: _vm.form.toTime },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form, "fromTime", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form, "toTime", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "反馈内容", prop: "content", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          title: "反馈内容",
                          width: "200",
                          trigger: "hover",
                          content: row.content
                        }
                      },
                      [
                        row.content
                          ? _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("ftEllipsis")(row.content))
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "反馈图片", prop: "images", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d2-flex",
                        staticStyle: {
                          "flex-wrap": "wrap",
                          "align-items": "center",
                          "justify-content": "center"
                        }
                      },
                      _vm._l(row.images, function(l, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "d2-mr-5" },
                          [
                            _c("base-image", {
                              attrs: { src: l, width: 40, height: 40 }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "反馈时间", prop: "createTime", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "反馈用户昵称", prop: "nickname", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户邮箱", prop: "email", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "手机号码", prop: "phoneNumber", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户ID", prop: "userNo", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button-group",
                      [
                        !row.replied
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEdit(row)
                                  }
                                }
                              },
                              [_vm._v("回复客户")]
                            )
                          : _vm._e(),
                        row.replied
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDel(row)
                                  }
                                }
                              },
                              [_vm._v("查看回复")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "base-dialog",
        _vm._b(
          {
            ref: "baseDialogForm",
            attrs: { visible: _vm.dialog.visible, loadBtn: _vm.dialog.loadBtn },
            on: {
              confirm: _vm.handleConfirm,
              "update:visible": function($event) {
                return _vm.$set(_vm.dialog, "visible", $event)
              },
              "update:loadBtn": function($event) {
                return _vm.$set(_vm.dialog, "loadBtn", $event)
              },
              "update:load-btn": function($event) {
                return _vm.$set(_vm.dialog, "loadBtn", $event)
              }
            }
          },
          "base-dialog",
          _vm.dialog,
          false
        ),
        [
          _c(
            "el-form-item",
            { attrs: { label: "回复", prop: "replyContent" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入回复",
                  disabled: _vm.dialog.isEdit
                },
                model: {
                  value: _vm.dialog.formData.replyContent,
                  callback: function($$v) {
                    _vm.$set(_vm.dialog.formData, "replyContent", $$v)
                  },
                  expression: "dialog.formData.replyContent"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片", prop: "replyImages" } },
            [
              _c("base-file", {
                attrs: {
                  isImg: "",
                  accept: "1",
                  limit: 9,
                  fileObjStr: _vm.dialog.formData.replyImagesStr
                },
                on: { success: _vm.successUpload }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-layer",
        _vm._b(
          {
            attrs: { visible: _vm.layer.visible },
            on: {
              "update:visible": function($event) {
                return _vm.$set(_vm.layer, "visible", $event)
              }
            }
          },
          "base-layer",
          _vm.layer,
          false
        ),
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1 } },
            [
              _c("el-descriptions-item", { attrs: { label: "回复内容" } }, [
                _vm._v(_vm._s(_vm.layer.formData.replyContent))
              ]),
              _c("el-descriptions-item", { attrs: { label: "回复图片" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d2-flex",
                    staticStyle: {
                      "flex-wrap": "wrap",
                      "align-items": "center",
                      "justify-content": "center"
                    }
                  },
                  _vm._l(_vm.layer.formData.replyImages, function(l, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "d2-mr-5" },
                      [
                        _c("base-image", {
                          attrs: { src: l, width: 80, height: 80 }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }