//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'base-radio-group',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    childrenType: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      default: ''
    }
  },
  data: function data() {
    return {
      radio: ''
    };
  },
  methods: {
    handleChange: function handleChange(e) {
      this.$emit('change', e);
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      if (!_this.value) {
        var _this$options, _this$options$;

        _this.$emit('change', (_this$options = _this.options) === null || _this$options === void 0 ? void 0 : (_this$options$ = _this$options[0]) === null || _this$options$ === void 0 ? void 0 : _this$options$.value);
      }
    });
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler() {
        this.radio = this.value;
      }
    }
  }
};