import "D:\\mzkj\\\u98DF\u7269\\food-pc\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\mzkj\\\u98DF\u7269\\food-pc\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\mzkj\\\u98DF\u7269\\food-pc\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\mzkj\\\u98DF\u7269\\food-pc\\node_modules\\core-js\\modules\\es.promise.finally.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App';
import VCharts from 'v-charts'; // 核心插件

import d2Admin from '@/plugin/d2admin'; // store

import store from '@/store/index'; // 引入mixins

import '@/mixins'; // 过滤器

import '@/filters'; // 自定义指令

import '@/directive'; // 菜单和路由设置

import router from './router';
import { menuHeader, menuAside } from '@/menu';
import { frameInRoutes } from '@/router/routes'; // 核心插件

Vue.use(d2Admin);
Vue.use(VCharts);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes); // 设置顶栏菜单

    this.$store.commit('d2admin/menu/headerSet', menuHeader); // 设置侧边栏菜单

    this.$store.commit('d2admin/menu/asideSet', menuAside); // 初始化菜单搜索功能

    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');