var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "range-input" },
    [
      _c(
        "el-input",
        _vm._b(
          {
            staticStyle: { width: "100px" },
            attrs: { type: _vm.type },
            model: {
              value: _vm.valueStart,
              callback: function($$v) {
                _vm.valueStart = $$v
              },
              expression: "valueStart"
            }
          },
          "el-input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" ~ "),
      _c(
        "el-input",
        _vm._b(
          {
            staticStyle: { width: "100px" },
            attrs: { type: _vm.type },
            model: {
              value: _vm.valueEnd,
              callback: function($$v) {
                _vm.valueEnd = $$v
              },
              expression: "valueEnd"
            }
          },
          "el-input",
          _vm.$attrs,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }