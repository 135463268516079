import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      loading: false,
      percentage: 0,
      colors: [{
        color: '#f56c6c',
        percentage: 20
      }, {
        color: '#e6a23c',
        percentage: 40
      }, {
        color: '#5cb87a',
        percentage: 60
      }, {
        color: '#1989fa',
        percentage: 80
      }, {
        color: '#6f7ad3',
        percentage: 100
      }]
    };
  },
  filters: {
    fromtText: function fromtText(val) {
      return val.toFixed(0);
    }
  },
  methods: {
    show: function show() {
      this.percentage = 0;
      this.loading = true;
    },
    hide: function hide() {
      this.loading = false;
    },
    set: function set(num) {
      var _this = this;

      console.log('num :>> ', num);
      setTimeout(function () {
        _this.percentage = Number(num);
      }, 100);
    },
    increase: function increase() {
      this.percentage += 10;

      if (this.percentage > 100) {
        this.percentage = 100;
      }
    },
    decrease: function decrease() {
      this.percentage -= 10;

      if (this.percentage < 0) {
        this.percentage = 0;
      }
    }
  }
};