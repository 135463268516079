//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
export default {
  name: 'base-form',
  props: {
    initForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    form: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      rules: {
        userLike: [{
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$emit('search', _this.form);

          _this.$emit('submit', _this.form);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    reset: function reset() {
      this.$emit('update:form', cloneDeep(this.initForm));
      this.$emit('search', cloneDeep(this.initForm));
      this.$emit('onReset');
    }
  },
  mounted: function mounted() {
    console.log('$attrs:', this.$attrs);
  }
};