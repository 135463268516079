import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import * as dayjs from 'dayjs';

function getDay(day) {
  var str = '';
  var toDay = dayjs().subtract(day, 'day').day();

  switch (toDay) {
    case 0:
      str = '星期日';
      break;

    case 1:
      str = '星期一';
      break;

    case 2:
      str = '星期二';
      break;

    case 3:
      str = '星期三';
      break;

    case 4:
      str = '星期四';
      break;

    case 5:
      str = '星期五';
      break;

    case 6:
      str = '星期六';
      break;
  }

  return str;
}

export default {
  data: function data() {
    this.chartExtend = {
      series: function series(v) {
        v.forEach(function (i) {
          i.barWidth = 10;
        });
        return v;
      },
      tooltip: function tooltip(v) {
        v.trigger = 'none';
        return v;
      }
    };
    return {
      chartData: {
        columns: ['日期', '系统销售订单金额', '用户充值金额', '市场转售', '用户提现金额'],
        rows: [{
          日期: getDay(6),
          系统销售订单金额: _.random(100),
          用户充值金额: _.random(100),
          市场转售: _.random(100),
          用户提现金额: _.random(100)
        }, {
          日期: getDay(5),
          系统销售订单金额: _.random(100),
          用户充值金额: _.random(100),
          市场转售: _.random(100),
          用户提现金额: _.random(100)
        }, {
          日期: getDay(4),
          系统销售订单金额: _.random(100),
          用户充值金额: _.random(100),
          市场转售: _.random(100),
          用户提现金额: _.random(100)
        }, {
          日期: getDay(3),
          系统销售订单金额: _.random(100),
          用户充值金额: _.random(100),
          市场转售: _.random(100),
          用户提现金额: _.random(100)
        }, {
          日期: getDay(2),
          系统销售订单金额: _.random(100),
          用户充值金额: _.random(100),
          市场转售: _.random(100),
          用户提现金额: _.random(100)
        }, {
          日期: getDay(1),
          系统销售订单金额: _.random(100),
          用户充值金额: _.random(100),
          市场转售: _.random(100),
          用户提现金额: _.random(100)
        }, {
          日期: getDay(0),
          系统销售订单金额: _.random(100),
          用户充值金额: _.random(100),
          市场转售: _.random(100),
          用户提现金额: _.random(100)
        }]
      }
    };
  }
};