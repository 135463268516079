import "core-js/modules/es.array.filter";
import dayjs from 'dayjs';
import Vue from 'vue';
/** 年月日时分秒 */

Vue.filter('formatDateTime', function (timeStr) {
  return formatDateTime(timeStr);
});
/** 年月日 */

Vue.filter('formatDate', function (timeStr) {
  return formatDate(timeStr);
});
/** 时分秒 */

Vue.filter('formatTime', function (timeStr) {
  return formatTime(timeStr);
});
/** 日期时间格式 */

export var EDTFormat = {
  /** 日期 */
  DATE: 'YYYY-MM-DD',

  /** 时间 */
  TIME: 'HH:mm:ss',

  /** 时间 */
  TIME_HM: 'HH:mm',

  /** 日期时间 */
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',

  /** 日期时间 - 没有秒 */
  DATE_TIME_HM: 'YYYY-MM-DD HH:mm'
};

function format(dateTime) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EDTFormat.DATE_TIME;

  if (!dateTime) {
    return '';
  }

  return dayjs(dateTime).format(formatStr);
}
/** 年月日 */


export function formatDate(dateTime) {
  return format(dateTime, EDTFormat.DATE);
}
/** 时分秒 */

export function formatTime(dateTime) {
  return format(dateTime, EDTFormat.TIME);
}
/** 年月日时分秒 */

export function formatDateTime(dateTime) {
  return format(dateTime, EDTFormat.DATE_TIME);
}
/** 年月日时分 */

export function formatDateTimeHM(dateTime) {
  return format(dateTime, EDTFormat.DATE_TIME_HM);
}
/** 日期转时间戳 */

export var date2Time = function date2Time(date) {
  var YLdateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EDTFormat.DATE;
  return date ? dayjs(date, YLdateFormat).valueOf() : null;
};