//自定义指令：实现clipboard 复制功能
import Vue from 'vue';
import Clipboard from 'clipboard';
import $message from '@/libs/util.message'; // v-copy: 实现clipboard 复制功能

Vue.directive('copy', {
  bind: function bind(el, binding, vnode, oldVnode) {
    var clipboard = new Clipboard(el, {
      text: function text() {
        return binding.value;
      }
    });
    clipboard.on('success', function (e) {
      $message.success('复制成功');
      e.clearSelection();
    });
    clipboard.on('error', function (e) {
      if (~e.text) {
        $message.error('复制失败');
      }
    });
  }
});