import "core-js/modules/es.array.fill";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.some";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'cascader-select',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    maxLevel: {
      type: Number,
      default: -1
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      selectOptions: [],
      selectValues: [],
      selectedItems: {}
    };
  },
  methods: {
    reset: function reset() {
      this.selectValues = [];
      this.selectedItems = {};
      this.$set(this.selectOptions, 0, this.options);
      this.selectOptions.splice(1, this.selectOptions.length);
    },
    handleChange: function handleChange(index) {
      var _this$selectValues;

      var value = this.selectValues[index];
      var selectedItem = this.selectOptions[index].find(function (item) {
        return item.value === value;
      });
      this.selectedItems[index] = selectedItem;
      this.$set(this.selectOptions, index + 1, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.children);
      this.selectValues.splice(index + 1, this.selectValues.length); // 清空子级数据

      if (!value) {
        this.selectOptions.splice(index + 1, this.selectOptions.length);
      }

      this.$emit('change', (_this$selectValues = this.selectValues) === null || _this$selectValues === void 0 ? void 0 : _this$selectValues.filter(function (item) {
        return !!item;
      }), this.selectedItems);
    }
  },
  computed: {
    selects: function selects() {
      var listCount = 1;

      var findChildren = function findChildren(list) {
        if (list === null || list === void 0 ? void 0 : list.some(function (item) {
          return !!item.children;
        })) {
          listCount += 1;
          var item = list.find(function (item) {
            return !!item.children;
          });
          findChildren(item.children);
        }
      };

      findChildren(this.selectOptions[0]);

      if (this.maxLevel > 0 && this.maxLevel < listCount) {
        return new Array(this.maxLevel).fill('');
      }

      return new Array(listCount).fill('');
    }
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.$set(this.selectOptions, 0, this.options);
      }
    }
  }
};