var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-login" },
    [
      _c("div", { staticClass: "page-login--layer page-login--layer-area" }, [
        _c(
          "ul",
          { staticClass: "circles" },
          _vm._l(10, function(n) {
            return _c("li", { key: n })
          }),
          0
        )
      ]),
      _vm._m(0),
      _c("div", { staticClass: "page-login--layer" }, [
        _c(
          "div",
          {
            staticClass: "page-login--content",
            attrs: { flex: "dir:top main:justify cross:stretch box:justify" }
          },
          [
            _vm._m(1),
            _c(
              "div",
              {
                staticClass: "page-login--content-main",
                attrs: { flex: "dir:top main:center cross:center" }
              },
              [
                _c("div", { staticClass: "page-login--logo" }, [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.$baseUrl +
                        "image/theme/" +
                        _vm.themeActiveSetting.name +
                        "/logo/all.png"
                    }
                  }),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "40px", color: "#fff" } },
                    [_vm._v(_vm._s(_vm.$t("guan-li-xi-tong")))]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "page-login--form" },
                  [
                    _c(
                      "el-card",
                      { attrs: { shadow: "never" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "loginForm",
                            attrs: {
                              "label-position": "top",
                              rules: _vm.rules,
                              model: _vm.formLogin,
                              size: "default"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "username" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { "max-width": "100%" },
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("yong-hu-ming")
                                    },
                                    model: {
                                      value: _vm.formLogin.username,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formLogin, "username", $$v)
                                      },
                                      expression: "formLogin.username"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-user-circle-o",
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { "max-width": "100%" },
                                    attrs: {
                                      type: "password",
                                      placeholder: _vm.$t("mi-ma")
                                    },
                                    model: {
                                      value: _vm.formLogin.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formLogin, "password", $$v)
                                      },
                                      expression: "formLogin.password"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-keyboard-o",
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "button-login",
                                attrs: { size: "default", type: "primary" },
                                on: { click: _vm.submit }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("login")) + " ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "page-login--content-footer" })
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "快速选择用户",
            visible: _vm.dialogVisible,
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { margin: "-20px 0px -10px 0px" },
              attrs: { gutter: 10 }
            },
            _vm._l(_vm.users, function(user, index) {
              return _c("el-col", { key: index, attrs: { span: 8 } }, [
                _c(
                  "div",
                  {
                    staticClass: "page-login--quick-user",
                    on: {
                      click: function($event) {
                        return _vm.handleUserBtnClick(user)
                      }
                    }
                  },
                  [
                    _c("d2-icon", { attrs: { name: "user-circle-o" } }),
                    _c("span", [_vm._v(_vm._s(user.name))])
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        1
      ),
      _c("loginCanvas")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "page-login--layer page-login--layer-time",
        attrs: { flex: "main:center cross:center" }
      },
      [
        _c("img", {
          attrs: { src: require("./image/login.56e96ba6.png"), alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-login--content-header" }, [
      _c("p", { staticClass: "page-login--content-header-motto" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }