import "core-js/modules/es.array.includes";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.string.includes";
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'image-upload',
  data: function data() {
    return {
      uploadFileUrls: []
    };
  },
  props: {
    maxCount: {
      type: Number,
      default: 1
    },
    srcList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },

    /** 最后一张图片 */
    url: {
      type: String,
      default: ''
    }
  },
  methods: {
    openUploadModal: function openUploadModal() {
      this.$refs.upload.open();
    },
    handleUpload: function handleUpload(url) {
      if (!this.uploadFileUrls.includes(url)) {
        this.uploadFileUrls.push(url);
      }
    }
  },
  watch: {
    uploadFileUrls: {
      deep: true,
      handler: function handler() {
        var _this$uploadFileUrls;

        this.$emit('update:srcList', this.uploadFileUrls);
        this.$emit('update:url', (_this$uploadFileUrls = this.uploadFileUrls) === null || _this$uploadFileUrls === void 0 ? void 0 : _this$uploadFileUrls[this.uploadFileUrls.length - 1]);
      }
    }
  }
};