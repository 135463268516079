//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import localeMixin from '@/locales/mixin.js';
export default {
  mixins: [localeMixin],
  mounted: function mounted() {
    console.log(this.$languages);
    console.log(this.$i18n.locale);
  }
};