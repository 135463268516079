var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户昵称" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户邮箱" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户邮箱" },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户ID" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户ID" },
                model: {
                  value: _vm.form.userNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userNo", $$v)
                  },
                  expression: "form.userNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "购买时间段" } },
            [
              _c("range-date", {
                attrs: { min: _vm.form.fromTime, max: _vm.form.toTime },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form, "fromTime", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form, "toTime", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "会员类型" } },
            [
              _c("base-select", {
                attrs: { options: _vm.dict.packingDict },
                model: {
                  value: _vm.form.vipType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "vipType", $$v)
                  },
                  expression: "form.vipType"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "用户昵称", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户ID", prop: "userNo", width: "140" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户邮箱", prop: "email", width: "220" }
          }),
          _c("el-table-column", {
            attrs: { label: "会员类型", prop: "vipType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValuelabel")(
                            row.vipType,
                            _vm.dict.packingDict
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "会员价格", prop: "priceAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "购买时间", prop: "validStartDate", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "到期时间", prop: "validEndDate", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "支付方式", prop: "payWay" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValuelabel")(
                            row.payWay,
                            _vm.dict.payTypeDict
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }