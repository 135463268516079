import _objectSpread from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
import util from '@/libs/util';
import { mapGetters } from 'vuex';
export default {
  name: 'app',
  watch: {
    '$i18n.locale': 'i18nHandle',
    stateLoad: function stateLoad(val) {
      //打开，关闭
      var _this$$refs$baseLoad = this.$refs.baseLoad,
          open = _this$$refs$baseLoad.open,
          close = _this$$refs$baseLoad.close; //监听状态值得变化，true代表开始加载,false表示关闭加载

      if (val) {
        open && open();
      } else {
        close && close();
      }
    }
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);
  },
  computed: _objectSpread({}, mapGetters('d2admin/app', ['stateLoad'])),
  methods: {
    i18nHandle: function i18nHandle(val, oldVal) {
      util.cookies.set('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    }
  }
};