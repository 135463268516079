import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {}
    },
    type: {
      type: Number,
      default: 0
    },
    effect: {
      type: String,
      default: 'dark'
    }
  },
  computed: {
    typeTag: function typeTag() {
      return ['success', 'info', 'warning', 'danger'][this.type];
    }
  }
};