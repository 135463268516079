var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-gallery" },
    _vm._l(_vm.srcList, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "image-box" },
        [
          _c("el-image", {
            style: _vm.style,
            attrs: { src: item, fit: "cover", "preview-src-list": _vm.srcList }
          }),
          _vm.canDelete
            ? _c("i", {
                staticClass: "el-icon-delete del-btn",
                on: {
                  click: function($event) {
                    return _vm.onDel(index)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }