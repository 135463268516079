var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d2-card" }, [
    _vm.title
      ? _c("div", { staticClass: "d2-card_tit" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "d2-card_box" },
      [
        _vm.src
          ? _c("div", [_c("base-image", { attrs: { src: _vm.src } })], 1)
          : _vm._e(),
        _c("div", { staticClass: "d2-card_name" }, [_vm._t("name")], 2),
        _c("div", { staticClass: "d2-card_desc" }, [_vm._t("desc")], 2),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }