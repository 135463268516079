import { mock, Random } from 'mockjs';
import util from '@/libs/util';
var cname = ['番茄', '西红柿', '黄瓜', '青瓜'];

var tableList = function tableList(pageSize) {
  var result = [];
  util.doCustomTimes(pageSize, function () {
    result.push(mock({
      id: '@integer(10,999999)',
      beginTime: '@datetime',
      endTime: '@datetime',
      address: '@city()',
      name: '@cname()',
      idCardName: '@cname()',
      idCardNumer: mock(/\d{18}/),
      phone: '176' + mock(/\d{8}/),
      avatar: Random.image('400x400', Random.color(), Random.color(), Random.first()),
      desc: '@ctitle',
      date: "@date('yyyy-MM-dd')",
      time: "@time('HH:mm')",
      'no|100000-10000000': 100000,
      'status|1': [true, false],
      conut: '@integer(2,99)',
      code: mock(/\d{6}/),
      link: '@url',
      datetime: '@datetime',
      'type|1': [1, 2],
      diiName: cname[Random.integer(0, 3)],
      email: '@email'
    }));
  });
  return result;
};

export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 列表
     */
    table_lists: function table_lists() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // 模拟数据
      mock.onAny('/list').reply(function (config) {
        return tools.responseSuccess({
          records: tableList(10),
          total: 20,
          pageNo: 1,
          pageSize: 10
        });
      }); // 接口请求

      return requestForMock({
        url: '/list',
        method: 'post',
        data: data
      });
    }
  };
});