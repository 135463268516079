import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @description 支持 jsx 写法的表单格，使用例子如下
 *  <form-grid :options="[
   {
      label: '用户ID',
      value: 'xxx',
      render: () => {
        return <span style="width: 100px;backgound:red">{this.url}</span>
      }
    },
 ]" />
 */
import JsxRender from '@/components/global/common/JsxRender.vue';
export default {
  name: 'form-grid',
  components: {
    JsxRender: JsxRender
  },
  props: {
    border: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },

    /**
     * @param {string} direction 'vertical' | 'horizontal'
     */
    direction: {
      type: String,
      default: 'vertical'
    },
    column: {
      type: Number,
      default: 4
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {}
};