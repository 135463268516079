//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    width: {
      type: String,
      default: '40%'
    },
    visible: {
      default: false
    },
    title: {
      type: String
    },
    fullTitle: {
      type: String
    },
    formData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    loadBtn: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    buttons: {
      type: Array,
      default: function _default() {
        return ['cancel', 'confirm'];
      }
    },
    top: {
      type: String,
      default: '15vh'
    }
  },
  data: function data() {
    return {
      form: {}
    };
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$refs['form'].resetFields();
      this.$emit('update:visible', false);
      this.$emit('update:loadBtn', false);
    },
    onChancel: function onChancel() {
      this.$emit('cancel');
    },
    onChancel1: function onChancel1() {
      this.$emit('update:visible', false);
      this.$emit('update:loadBtn', false);
    },
    onConfirm: function onConfirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$emit('update:loadBtn', true);

          _this.$emit('confirm', _this.form);
        } else {
          console.log('error confirm!!');

          _this.$msg.error('表单信息验证失败');

          return false;
        }
      });
    },
    clearValidate: function clearValidate() {
      var _this$$refs$form;

      (_this$$refs$form = this.$refs.form) === null || _this$$refs$form === void 0 ? void 0 : _this$$refs$form.clearValidate();
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler: function handler() {
        var _this2 = this;

        if (this.visible) {
          this.$nextTick(function () {
            _this2.$refs.form.resetFields();
          });
        } else {
          console.log('close');
          this.form = {};
        }
      }
    },
    formData: {
      deep: true,
      handler: function handler(val) {
        this.$nextTick(function () {});
        this.$set(this, 'form', _.cloneDeep(val));
      }
    }
  },
  computed: {
    titleLabel: function titleLabel() {
      if (this.fullTitle) {
        return this.fullTitle;
      }

      return "".concat(this.isEdit ? "\u7F16\u8F91".concat(this.title) : "\u65B0\u589E".concat(this.title));
    }
  }
};