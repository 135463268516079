import _objectSpread from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import Vue from 'vue';
import { mapState } from 'vuex';
import mUtil from '@/libs/util';
var minixs = {
  data: function data() {
    return {
      qnToken: '',
      // defaultAvatar: 'https://pica.zhimg.com/v2-379251a562618b581c2798a77b689ae0_1440w.jpg',
      defaultAvatar: '',
      mUtil: mUtil,
      valueFormats: ['yyyy-MM-dd', 'yyyy-MM-dd HH:mm:ss']
    };
  },
  computed: _objectSpread({}, mapState('d2admin', {
    dict: function dict(state) {
      return state.dict;
    }
  })),
  methods: {
    had: function had() {
      var _this$$check;

      return (_this$$check = this.$check).had.apply(_this$$check, arguments);
    },
    //切换加载状态
    showLoad: function showLoad() {
      this.$store.dispatch('d2admin/app/showLoad');
    },
    hideLoad: function hideLoad() {
      this.$store.dispatch('d2admin/app/hideLoad');
    },
    openImageView: function openImageView(link) {
      var multiple = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

      if (!multiple) {
        // 打开图片预览
        this.$hevueImgPreview(link);
      } else {
        // 多图预览
        this.$hevueImgPreview({
          multiple: true,
          // 开启多图预览模式
          nowImgIndex: index,
          // 多图预览，默认展示第一张图片
          imgList: link,
          // 需要预览的多图数组
          mainBackground: 'rgba(0, 0, 0, .5)' // 整体背景颜色

        });
      }
    },
    goRoute: function goRoute(path) {
      this.$router.push(path);
    },
    redirectTo: function redirectTo(path) {
      var currentPage = this.$store.state.d2admin.page.current;
      this.$store.dispatch('d2admin/page/close', {
        tagName: currentPage
      });
      this.$router.push(path);
    },
    goBack: function goBack() {
      var currentPage = this.$store.state.d2admin.page.current;
      console.log('goBack', currentPage);
      this.$store.dispatch('d2admin/page/close', {
        tagName: currentPage
      });
      this.$router.back();
    }
  }
};
Vue.mixin(minixs);