var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "base-submit",
        {
          ref: "ruleForm",
          attrs: { form: _vm.form, formRef: "ruleForm", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "免费会员时间",
                prop: "freeDays",
                "label-width": "130px"
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入免费会员时间" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [_vm._v("天")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.form.freeDays,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "freeDays", _vm._n($$v))
                  },
                  expression: "form.freeDays"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-s-promotion" },
                  on: { click: _vm.clickSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }