//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    total: {
      default: 0
    }
  },
  data: function data() {
    return {
      currentPage: 1,
      pageSize: 10
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange(size) {
      this.pageSize = size;
      this.onChange();
    },
    handleCurrentChange: function handleCurrentChange() {
      this.onChange();
    },
    onChange: function onChange() {
      this.$emit('call-data', {
        pageIndex: this.currentPage,
        pageSize: this.pageSize
      });
    },

    /** 对外暴露 */
    loadWithPageInfo: function loadWithPageInfo() {
      var isReset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (isReset) {
        this.currentPage = 1;
      }

      this.onChange();
    }
  }
};