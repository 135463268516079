var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tag",
    _vm._g(
      _vm._b(
        {
          staticClass: "el-tag",
          attrs: {
            type: _vm.typeTag,
            effect: _vm.effect,
            "disable-transitions": ""
          }
        },
        "el-tag",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }