import _objectSpread from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('d2admin/fullscreen', ['active'])),
  methods: _objectSpread({}, mapActions('d2admin/fullscreen', ['toggle']))
};