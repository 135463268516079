export default {
  namespaced: true,
  state: {
    // ROLE_LEADER (机关6级)
    // ROLE_DIRECTER // (区域主任5级)
    // ROLE_COMMUNITY：社区管家(4级)
    // ROLE_BUILDING：楼长（3级）
    // ROLE_ENSURER_LEADER： 保安组长（2级）
    // ROLE_MTS_LEADER： 维修组长（2级）
    // ROLE_CLEANER： 保洁员
    // ROLE_ENSURER： 保安
    // ROLE_MTS： 维修
    // ROLE_CS： 客服
    powerKey: ['', 'ROLE_CS', 'ROLE_MTS', 'ROLE_ENSURER', 'ROLE_CLEANER', 'ROLE_MTS_LEADER', 'ROLE_ENSURER_LEADER', 'ROLE_BUILDING', 'ROLE_COMMUNITY', 'ROLE_DIRECTER', 'ROLE_LEADER'],
    powerStatus: [{
      value: 1,
      label: '客服'
    }, {
      value: 2,
      label: '维修组员（1级）'
    }, {
      value: 3,
      label: '保安组员（1级）'
    }, {
      value: 4,
      label: '保洁员（1级）'
    }, {
      value: 5,
      label: '维修组长（2级）'
    }, {
      value: 6,
      label: '保安组长（2级）'
    }, {
      value: 7,
      label: '楼管家（3级）'
    }, {
      value: 8,
      label: '社区管家（4级）'
    }, {
      value: 9,
      label: '区域主任（5级）'
    }, {
      value: 10,
      label: '机关（6级）'
    }],
    powerStatus1234: [{
      value: 1,
      label: '客服'
    }, {
      value: 2,
      label: '维修组员（1级）'
    }, {
      value: 3,
      label: '保安组员（1级）'
    }, {
      value: 4,
      label: '保洁员（1级）'
    }, {
      value: 5,
      label: '维修组长（2级）'
    }, {
      value: 6,
      label: '保安组长（2级）'
    }, {
      value: 7,
      label: '楼管家（3级）'
    }, {
      value: 8,
      label: '社区管家（4级）'
    }],
    powerStatus56: [{
      value: 9,
      label: '区域主任（5级）'
    }, {
      value: 10,
      label: '机关（6级）'
    }],
    powerStatus1: [{
      value: 1,
      label: '碧桂园'
    }],
    logType: [{
      value: 1,
      label: '提交发现'
    }, {
      value: 2,
      label: '提交发现并解决'
    }, {
      value: 3,
      label: '后台扣减'
    }],
    trueStatus: [{
      value: true,
      label: '是'
    }, {
      value: false,
      label: '否'
    }],
    sexStatus: [{
      value: 1,
      label: '男'
    }, {
      value: 2,
      label: '女'
    }],
    sexStatusList: [{
      value: 0,
      label: '不详'
    }, {
      value: 1,
      label: '男'
    }, {
      value: 2,
      label: '女'
    }],
    //包装要求
    packingDict: [{
      value: 1,
      label: '周卡'
    }, {
      value: 2,
      label: '月卡'
    }, {
      value: 3,
      label: '季卡'
    }, {
      value: 4,
      label: '半年卡'
    }, {
      value: 5,
      label: '年卡'
    }],
    //食物名称
    qualityDict: [{
      value: '主食',
      label: '主食'
    }, {
      value: '蔬果',
      label: '蔬果'
    }, {
      value: '肉蛋奶',
      label: '肉蛋奶'
    }, {
      value: '豆类坚果',
      label: '豆类坚果'
    }, {
      value: '零食饮料',
      label: '零食饮料'
    }, {
      value: '菜肴',
      label: '菜肴'
    }, {
      value: '其他',
      label: '其他'
    }],
    //会员状态
    memberDict: [{
      value: 1,
      label: '生效中'
    }, {
      value: 2,
      label: '已失效'
    }],
    //会员状态
    linkList: [{
      value: 1,
      label: '富文本'
    }, {
      value: 2,
      label: '链接'
    }],
    //会员年数
    memberYearDict: [{
      value: 1,
      label: '1年'
    }, {
      value: 2,
      label: '2年'
    }, {
      value: 3,
      label: '3年'
    }],
    //会员年数
    memberYearDictMber: [{
      value: 1,
      label: '1年会员'
    }, {
      value: 2,
      label: '2年会员'
    }, {
      value: 3,
      label: '3年会员'
    }],
    //支付方式
    payTypeDict: [{
      value: 1,
      label: 'PayPal'
    }, {
      value: 2,
      label: '苹果内购'
    }, {
      value: 3,
      label: '优惠卷兑换'
    }],
    //支付方式
    authenDict: [{
      value: 1,
      label: '待审核'
    }, {
      value: 2,
      label: '审核成功'
    }, {
      value: 3,
      label: '审核失败'
    }],
    shenheDict: [{
      value: 2,
      label: '成功'
    }, {
      value: 3,
      label: '失败'
    }]
  }
};