//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'base-layer',
  props: {
    width: {
      type: String,
      default: '55%'
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示信息'
    },
    buttons: {
      type: Array,
      default: function _default() {
        return ['cancel', 'confirm'];
      }
    },
    loadBtn: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: '15vh'
    }
  },
  data: function data() {
    return {
      isShow: false
    };
  },
  watch: {
    visible: function visible(val) {
      this.isShow = val;
    }
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$emit('update:visible', false);
      this.$emit('update:loadBtn', false);
    },
    onChancel: function onChancel() {
      this.$emit('cancel');
      this.closeDialog();
    },
    confirm: function confirm() {
      this.$emit('update:loadBtn', true);
      this.$emit('confirm');
    }
  }
};