//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import headerComp from './components/header';
import userComp from './components/user';
import dataComp from './components/data';
export default {
  components: {
    headerComp: headerComp,
    userComp: userComp,
    dataComp: dataComp
  },
  computed: {
    bgImg: function bgImg() {
      //随机
      return require("./image/".concat(Math.floor(Math.random() * 5) + 1, "_bg.svg"));
    }
  }
};