import "core-js/modules/es.array.map";
import _objectSpread from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { throttle } from 'lodash';
import { mapState } from 'vuex';
import menuMixin from '../mixin/menu';
import { createMenu } from '../libs/util.menu';
export default {
  name: 'd2-layout-header-aside-menu-header',
  mixins: [menuMixin],
  render: function render(h) {
    var _this = this;

    return h("div", {
      "attrs": {
        "flex": "cross:center"
      },
      "class": {
        'd2-theme-header-menu': true,
        'is-scrollable': this.isScroll
      },
      "ref": "page"
    }, [h("div", {
      "ref": "content",
      "class": "d2-theme-header-menu__content",
      "attrs": {
        "flex-box": "1",
        "flex": true
      }
    }, [h("div", {
      "class": "d2-theme-header-menu__scroll",
      "attrs": {
        "flex-box": "0"
      },
      "style": {
        transform: "translateX(".concat(this.currentTranslateX, "px)")
      },
      "ref": "scroll"
    }, [h("el-menu", {
      "attrs": {
        "mode": "horizontal",
        "defaultActive": this.active
      },
      "on": {
        "select": this.handleMenuSelect
      }
    }, [this.header.map(function (menu) {
      return createMenu.call(_this, h, menu);
    })])])]), this.isScroll ? [h("div", {
      "class": "d2-theme-header-menu__prev",
      "attrs": {
        "flex": "main:center cross:center",
        "flex-box": "0"
      },
      "on": {
        "click": function click() {
          return _this.scroll('left');
        }
      }
    }, [h("i", {
      "class": "el-icon-arrow-left"
    })]), h("div", {
      "class": "d2-theme-header-menu__next",
      "attrs": {
        "flex": "main:center cross:center",
        "flex-box": "0"
      },
      "on": {
        "click": function click() {
          return _this.scroll('right');
        }
      }
    }, [h("i", {
      "class": "el-icon-arrow-right"
    })])] : []]);
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['header'])),
  data: function data() {
    return {
      active: '',
      isScroll: false,
      scrollWidth: 0,
      contentWidth: 0,
      currentTranslateX: 0,
      throttledCheckScroll: null
    };
  },
  watch: {
    '$route.matched': {
      handler: function handler(val) {
        this.active = val[val.length - 1].path;
      },
      immediate: true
    }
  },
  methods: {
    scroll: function scroll(direction) {
      if (direction === 'left') {
        // 向右滚动
        this.currentTranslateX = 0;
      } else {
        // 向左滚动
        if (this.contentWidth * 2 - this.currentTranslateX <= this.scrollWidth) {
          this.currentTranslateX -= this.contentWidth;
        } else {
          this.currentTranslateX = this.contentWidth - this.scrollWidth;
        }
      }
    },
    checkScroll: function checkScroll() {
      var _this2 = this;

      var contentWidth = this.$refs.content.clientWidth;
      var scrollWidth = this.$refs.scroll.clientWidth;

      if (this.isScroll) {
        // 页面依旧允许滚动的情况，需要更新width
        if (this.contentWidth - this.scrollWidth === this.currentTranslateX) {
          // currentTranslateX 也需要相应变化【在右端到头的情况时】
          this.currentTranslateX = contentWidth - scrollWidth; // 快速的滑动依旧存在判断和计算时对应的contentWidth变成正数，所以需要限制一下

          if (this.currentTranslateX > 0) {
            this.currentTranslateX = 0;
          }
        } // 更新元素数据


        this.contentWidth = contentWidth;
        this.scrollWidth = scrollWidth; // 判断何时滚动消失: 当scroll > content

        if (contentWidth > scrollWidth) {
          this.isScroll = false;
        }
      } // 判断何时滚动出现: 当scroll < content


      if (!this.isScroll && contentWidth < scrollWidth) {
        this.isScroll = true; // 注意，当isScroll变为true，对应的元素盒子大小会发生变化

        this.$nextTick(function () {
          contentWidth = _this2.$refs.content.clientWidth;
          scrollWidth = _this2.$refs.scroll.clientWidth;
          _this2.contentWidth = contentWidth;
          _this2.scrollWidth = scrollWidth;
          _this2.currentTranslateX = 0;
        });
      }
    }
  },
  mounted: function mounted() {
    // 初始化判断
    // 默认判断父元素和子元素的大小，以确定初始情况是否显示滚动
    this.checkScroll(); // 全局窗口变化监听，判断父元素和子元素的大小，从而控制isScroll的开关

    this.throttledCheckScroll = throttle(this.checkScroll, 300);
    window.addEventListener('resize', this.throttledCheckScroll);
  },
  beforeDestroy: function beforeDestroy() {
    // 取消监听
    window.removeEventListener('resize', this.throttledCheckScroll);
  }
};