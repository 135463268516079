import { Message } from 'element-ui';
var msg = {
  success: function success(msg) {
    Message({
      message: msg,
      type: 'success'
    });
  },
  error: function error(msg) {
    Message({
      message: msg,
      type: 'error'
    });
  },
  warning: function warning(msg) {
    Message({
      message: msg,
      type: 'warning'
    });
  },
  info: function info(msg) {
    Message({
      message: msg,
      type: 'info'
    });
  }
};
export default msg;