var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "base-table",
        { ref: "baseTable", attrs: { data: _vm.dataList, pagination: false } },
        [
          _c("el-table-column", {
            attrs: { label: "食物种类", prop: "label" }
          }),
          _c("el-table-column", { attrs: { label: "保质期", prop: "days" } }),
          _c("el-table-column", {
            attrs: { label: "操作", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(row)
                              }
                            }
                          },
                          [_vm._v(" 修改 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "base-dialog",
        _vm._b(
          {
            ref: "baseDialogForm",
            attrs: { visible: _vm.dialog.visible, loadBtn: _vm.dialog.loadBtn },
            on: {
              confirm: _vm.handleConfirm,
              "update:visible": function($event) {
                return _vm.$set(_vm.dialog, "visible", $event)
              },
              cancel: _vm.handleCancel,
              "update:loadBtn": function($event) {
                return _vm.$set(_vm.dialog, "loadBtn", $event)
              },
              "update:load-btn": function($event) {
                return _vm.$set(_vm.dialog, "loadBtn", $event)
              }
            }
          },
          "base-dialog",
          _vm.dialog,
          false
        ),
        [
          _c(
            "el-form-item",
            { attrs: { label: "保质期", prop: "days" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入天数" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [_vm._v(" 天 ")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.dialog.formData.days,
                  callback: function($$v) {
                    _vm.$set(_vm.dialog.formData, "days", _vm._n($$v))
                  },
                  expression: "dialog.formData.days"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }