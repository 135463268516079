import "core-js/modules/es.array.fill";
//
//
//
//
import './jquery.js';
export default {
  mounted: function mounted() {
    $(function () {
      var f = document.querySelector('canvas'),
          p = f.getContext('2d');
      f.width = window.innerWidth;
      f.height = window.innerHeight;
      p.lineWidth = 0.3;
      p.strokeStyle = new d(150).style;
      var o = {
        x: 30 * f.width / 100,
        y: 30 * f.height / 100
      };
      var m = {
        nb: 750,
        distance: 50,
        d_radius: 100,
        array: []
      };

      function a(r) {
        return Math.floor(Math.random() * 255 + r);
      }

      function n(u, t, s) {
        return 'rgba(' + u + ',' + t + ',' + s + ', 0.8)';
      }

      function e(u, s, t, r) {
        return (u * s + t * r) / (s + r);
      }

      function g(y, x) {
        var u = y.color,
            t = x.color;
        var w = e(u.r, y.radius, t.r, x.radius),
            v = e(u.g, y.radius, t.g, x.radius),
            s = e(u.b, y.radius, t.b, x.radius);
        return n(Math.floor(w), Math.floor(v), Math.floor(s));
      }

      function d(r) {
        r = r || 0;
        this.r = a(r);
        this.g = a(r);
        this.b = a(r);
        this.style = n(this.r, this.g, this.b);
      }

      function c() {
        this.x = Math.random() * f.width;
        this.y = Math.random() * f.height;
        this.vx = -0.5 + Math.random();
        this.vy = -0.5 + Math.random();
        this.radius = Math.random() * 2;
        this.color = new d();
      }

      c.prototype = {
        draw: function draw() {
          p.beginPath();
          p.fillStyle = this.color.style;
          p.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
          p.fill();
        }
      };

      function k() {
        for (var i = 0; i < m.nb; i++) {
          m.array.push(new c());
        }
      }

      function q() {
        for (var i = 0; i < m.nb; i++) {
          var r = m.array[i];

          if (r.y < 0 || r.y > f.height) {
            r.vx = r.vx;
            r.vy = -r.vy;
          } else {
            if (r.x < 0 || r.x > f.width) {
              r.vx = -r.vx;
              r.vy = r.vy;
            }
          }

          r.x += r.vx;
          r.y += r.vy;
        }
      }

      function l() {
        for (var i = 0; i < m.nb; i++) {
          for (var j = 0; j < m.nb; j++) {
            var i_dot = m.array[i];
            var j_dot = m.array[j];

            if (i_dot.x - j_dot.x < m.distance && i_dot.y - j_dot.y < m.distance && i_dot.x - j_dot.x > -m.distance && i_dot.y - j_dot.y > -m.distance) {
              if (i_dot.x - o.x < m.d_radius && i_dot.y - o.y < m.d_radius && i_dot.x - o.x > -m.d_radius && i_dot.y - o.y > -m.d_radius) {
                p.beginPath();
                p.strokeStyle = g(i_dot, j_dot);
                p.moveTo(i_dot.x, i_dot.y);
                p.lineTo(j_dot.x, j_dot.y);
                p.stroke();
                p.closePath();
              }
            }
          }
        }
      }

      function h() {
        for (var i = 0; i < m.nb; i++) {
          var r = m.array[i];
          r.draw();
        }
      }

      function b() {
        p.clearRect(0, 0, f.width, f.height);
        q();
        l();
        h();
        requestAnimationFrame(b);
      }

      $('canvas').on('mousemove', function (r) {
        o.x = r.pageX;
        o.y = r.pageY;
      });
      $('canvas').on('mouseleave', function (r) {
        o.x = f.width / 2;
        o.y = f.height / 2;
      });
      k();
      requestAnimationFrame(b);
    });
  }
};