import "core-js/modules/es.array.includes";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.replace";
//自定义指令：实现element-ui对话框dialog拖拽功能
import Vue from 'vue'; // v-dialogDrag: 弹窗拖拽属性

Vue.directive('dialogDrag', {
  bind: function bind(el, binding, vnode, oldVnode) {
    var dialogHeaderEl = el.querySelector('.el-dialog__header');
    var dragDom = el.querySelector('.el-dialog');
    dialogHeaderEl.style.cursor = 'move'; // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);

    var sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);

    dialogHeaderEl.onmousedown = function (e) {
      // 鼠标按下，计算当前元素距离可视区的距离
      var disX = e.clientX - dialogHeaderEl.offsetLeft;
      var disY = e.clientY - dialogHeaderEl.offsetTop; // 获取到的值带px 正则匹配替换

      var styL, styT; // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px

      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
      } else {
        styL = +sty.left.replace(/\px/g, '');
        styT = +sty.top.replace(/\px/g, '');
      }

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        var l = e.clientX - disX;
        var t = e.clientY - disY; // 移动当前元素

        dragDom.style.left = "".concat(l + styL, "px");
        dragDom.style.top = "".concat(t + styT, "px"); //将此时的位置传出去
        //binding.value({x:e.pageX,y:e.pageY})
      };

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }
});