var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "base-submit",
        { attrs: { form: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "登录验证码", prop: "loginCode" } },
            [
              _c("base-radio-group", {
                attrs: { options: _vm.dict.offOn },
                model: {
                  value: _vm.form.loginCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "loginCode", $$v)
                  },
                  expression: "form.loginCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-s-promotion" },
                  on: { click: _vm.clickSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }