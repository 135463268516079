var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.title))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleAdd }
                    },
                    [_vm._v(" 生成条码 ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "批次名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入批次名称" },
                model: {
                  value: _vm.form.batchName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "batchName", $$v)
                  },
                  expression: "form.batchName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "TableTitleBar",
        { attrs: { card: "" } },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                labelStyle: {
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "bold"
                },
                contentStyle: {
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "bold"
                }
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "条码生成总数" } }, [
                _vm._v(_vm._s(_vm.extraData.barcodeCount || 0))
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "已使用条码数量" } },
                [_vm._v(_vm._s(_vm.extraData.usedCount || 0))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "未使用条码数量" } },
                [_vm._v(_vm._s(_vm.extraData.noUsedCount || 0))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "批次编号", prop: "batchNo", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "批次名称", prop: "batchName", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "条码数量", prop: "barcodeCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "提交生成条码数量", prop: "createCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "生成时间", prop: "createTime", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { label: "已使用", prop: "usedCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "未使用", prop: "noUsedCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", fixed: "right", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleSee(row)
                              }
                            }
                          },
                          [_vm._v(" 查看 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.handleDown(row)
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "info" },
                            on: {
                              click: function($event) {
                                return _vm.handlePrk(row)
                              }
                            }
                          },
                          [_vm._v(" 打印 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDel(row)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "base-dialog",
        _vm._b(
          {
            ref: "baseDialogForm",
            attrs: { visible: _vm.dialog.visible, loadBtn: _vm.dialog.loadBtn },
            on: {
              confirm: _vm.handleConfirm,
              "update:visible": function($event) {
                return _vm.$set(_vm.dialog, "visible", $event)
              },
              "update:loadBtn": function($event) {
                return _vm.$set(_vm.dialog, "loadBtn", $event)
              },
              "update:load-btn": function($event) {
                return _vm.$set(_vm.dialog, "loadBtn", $event)
              }
            }
          },
          "base-dialog",
          _vm.dialog,
          false
        ),
        [
          _c(
            "el-form-item",
            { attrs: { label: "条码数量", prop: "barcodeCount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入条码数量" },
                model: {
                  value: _vm.dialog.formData.barcodeCount,
                  callback: function($$v) {
                    _vm.$set(_vm.dialog.formData, "barcodeCount", $$v)
                  },
                  expression: "dialog.formData.barcodeCount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "批次名称", prop: "batchName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入批次名称" },
                model: {
                  value: _vm.dialog.formData.batchName,
                  callback: function($$v) {
                    _vm.$set(_vm.dialog.formData, "batchName", $$v)
                  },
                  expression: "dialog.formData.batchName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("xloading", { ref: "xloadingRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }