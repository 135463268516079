import "core-js/modules/es.array.filter";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import Vue from 'vue';

function fitHtmlImgWidth(content) {
  console.log('content:', content);
  var regex = new RegExp('<img', 'gi');
  return content === null || content === void 0 ? void 0 : content.replace(regex, '<img style="max-width: 100%; height: auto"');
}

Vue.filter('fitHtmlImgWidth', fitHtmlImgWidth);
export { fitHtmlImgWidth };