export default {
  title: '保质期',
  // iconSvg: 'd2-appset',
  sort: 3,
  path: '/integral-setup',
  icon: 'cogs' // children: [
  //   { path: '/authen-real', title: '实名认证审核', icon: 'id-card-o' },
  //   { path: '/authen-company', title: '公司认证审核', icon: 'handshake-o' },
  //   { path: '/authen-set', title: '设置审核方式', icon: 'cogs' },
  // ],

};