import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'base-checkbox-group',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      default: ''
    }
  },
  data: function data() {
    return {
      values: []
    };
  },
  methods: {
    handleChange: function handleChange(e, index) {
      var item = this.options[index]; // 选中

      if (e) {
        this.values = [].concat(_toConsumableArray(this.values), [item.value]);
      } else {
        this.values = this.values.filter(function (item) {
          return item === item.value;
        });
      }

      this.$emit('change', this.values);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler() {
        this.values = this.value;
      }
    }
  }
};