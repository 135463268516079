var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "x-loading" },
        [
          _c("el-progress", {
            attrs: {
              type: "dashboard",
              percentage: _vm.percentage,
              color: _vm.colors,
              "text-color": "#fff",
              "show-text": false
            }
          }),
          _c("div", { staticClass: "x-loading-text" }, [
            _c("div", [
              _vm._v(_vm._s(_vm._f("fromtText")(_vm.percentage)) + "%")
            ]),
            _c("div", [_vm._v("请稍后...")])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }