export default {
  title: '会员管理',
  iconSvg: 'd2-users',
  sort: 2,
  children: [{
    path: '/applet-set',
    title: '会员价格设置',
    iconSvg: 'd2-power'
  }, {
    path: '/applet-date',
    title: '免费会员时间',
    iconSvg: 'd2-power'
  }, {
    path: '/applet-users',
    title: '会员购买记录',
    icon: 'cogs'
  }]
};