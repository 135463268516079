import Vue from 'vue';
import Vuex from 'vuex';
import d2admin from './modules/d2admin';
Vue.use(Vuex);
window.wStore = d2admin.modules;
console.log('window.wStore', window.wStore);
export default new Vuex.Store({
  modules: {
    d2admin: d2admin
  }
});