//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'table-title-bar',
  props: {
    title: {
      default: ''
    },
    btnName: {
      default: ''
    },
    card: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  }
};