import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.reduce";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import { citys } from './citys';
var cityMap = citys.reduce(function (pre, cur) {
  cur.children.forEach(function (element) {
    pre[element.value.toString()] = element.label;
  });
  return pre;
}, {});
var districtMap = citys.reduce(function (pre, cur) {
  cur.children.forEach(function (element) {
    element.children.forEach(function (item) {
      pre[item.value.toString()] = item.label;
    });
  });
  return pre;
}, {});
export { cityMap, districtMap };