//
//
//
//
//
//
export default {
  name: 'range-input',
  model: {
    prop: 'value',
    event: 'onChange'
  },
  props: {
    type: {
      default: 'text'
    },
    min: {
      default: ''
    },
    max: {
      default: ''
    }
  },
  data: function data() {
    return {
      valueStart: '',
      valueEnd: ''
    };
  },
  watch: {
    min: function min(val) {
      this.valueStart = val;
    },
    max: function max(val) {
      this.valueEnd = val;
    },
    valueStart: {
      deep: true,
      handler: function handler() {
        this.$emit('update:min', this.valueStart);
      }
    },
    valueEnd: function valueEnd() {
      this.$emit('update:max', this.valueEnd);
    }
  },
  mounted: function mounted() {
    this.valueStart = this.min;
    this.valueEnd = this.max;
  }
};