var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-input",
        _vm._b(
          {
            staticStyle: { width: "180px", "vertical-align": "middle" },
            attrs: {
              type: _vm.type,
              min: _vm.min,
              max: _vm.max,
              disabled: _vm.disabled
            },
            on: {
              input: function($event) {
                return _vm.changeInt(_vm.value)
              }
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          },
          "el-input",
          _vm.$attrs,
          false
        ),
        [_c("template", { slot: "append" }, [_vm._v(_vm._s(_vm.text))])],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }