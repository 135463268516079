var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-card",
    [
      _c(
        "el-form",
        _vm._b(
          {
            ref: _vm.formRef,
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              "label-width": _vm.labelWidth
            }
          },
          "el-form",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }