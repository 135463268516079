import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV); // 自动读取.page.vue结尾的文件，写进路由


var files = require.context('../views', true, /\.page\.vue$/);

var generators = files.keys().map(function (key) {
  return {
    path: files(key).default.path || files(key).default.name,
    meta: _objectSpread(_objectSpread({}, files(key).default.meta), {}, {
      auth: true
    }),
    name: files(key).default.name,
    component: _import(key.replace('./', ''))
  };
});
/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  redirect: {
    name: process.env.VUE_APP_HOME_NAME
  },
  component: layoutHeaderAside,
  children: [// 首页
  // {
  //   path: 'index',
  //   name: 'index',
  //   meta: {
  //     auth: true,
  //     title: '首页',
  //   },
  //   component: _import('system/index'),
  // },
  // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }].concat(_toConsumableArray(generators))
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default [].concat(frameIn, frameOut, errorPage);