var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-descriptions",
    _vm._b(
      {
        staticClass: "form-grid",
        staticStyle: { width: "100%" },
        attrs: {
          column: _vm.column,
          border: _vm.border,
          direction: _vm.direction
        }
      },
      "el-descriptions",
      _vm.$attrs,
      false
    ),
    [
      _vm._l(_vm.options, function(item, index) {
        return _c(
          "el-descriptions-item",
          { key: index },
          [
            _c("template", { slot: "label" }, [
              _vm._v(" " + _vm._s(item.label) + " ")
            ]),
            item.render
              ? [_c("JsxRender", { attrs: { jsx: item.render() } })]
              : [_vm._v(_vm._s(item.value))]
          ],
          2
        )
      }),
      _vm._t("extra")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }