import { createAction } from '../utils';
export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    USER_account: createAction(request, '/admin/login/account'),
    //校验token时效性
    USER_checkTokenStatus: createAction(request, '/admin/admin/checkTokenStatus'),
    //修改密码
    USER_updatePassword: createAction(request, '/admin/admin/updatePassword'),
    //获取阿里云上传token
    USER_uploadToken: createAction(request, '/admin/admin/uploadToken'),
    //用户列表
    USER_list: createAction(request, '/admin/user/list'),
    //获取会员设置
    vip_config: createAction(request, '/admin/vip/config'),
    //保存会员设置
    vip_saveConfig: createAction(request, '/admin/vip/saveConfig'),
    //订单列表
    vip_orders: createAction(request, '/admin/vip/orders'),
    //项目成员列表
    USER_staffs: createAction(request, '/admin/project/staffs'),
    USER_items: createAction(request, '/admin/user/items'),
    USER_foods: createAction(request, '/admin/user/foods'),
    //保存项目成员
    USER_saveStaff: createAction(request, '/admin/project/saveStaff'),
    //冻结项目成员
    USER_lockStaff: createAction(request, '/admin/project/lockStaff'),
    //删除项目成员
    USER_deleteStaff: createAction(request, '/admin/project/deleteStaff'),
    //某项目下 积分用户列表
    USER_scoreUsers: createAction(request, '/admin/project/scoreUsers'),
    //某项目下 某个用户的积分明细
    USER_scoreLogs: createAction(request, '/admin/project/scoreLogs'),
    //某个项目下 扣减积分
    USER_subtractScore: createAction(request, '/admin/project/subtractScore'),
    //超级管理员列表(update)
    USER_accountList: createAction(request, '/admin/admin/accountList'),
    //添加超级管理员(update)
    USER_saveAccount: createAction(request, '/admin/admin/saveAccount'),
    //冻结超级管理员
    USER_lockAccount: createAction(request, '/admin/admin/lockAccount'),
    //删除超级管理员
    USER_deleteAccount: createAction(request, '/admin/admin/deleteAccount'),
    //食品保质期列表（分页）
    food_foodExpirations: createAction(request, '/admin/food/foodExpirations'),
    //删除食品保质期
    food_deleteExpiration: createAction(request, '/admin/food/deleteExpiration'),
    //添加食品保质期
    food_saveExpiration: createAction(request, '/admin/food/saveExpiration'),
    //反馈列表
    feedback_list: createAction(request, '/admin/feedback/list'),
    //回复反馈
    feedback_reply: createAction(request, '/admin/feedback/reply'),
    //轮播图列表
    USER_banners: createAction(request, '/admin/config/banners'),
    //删除轮播图
    USER_deleteBanner: createAction(request, '/admin/config/deleteBanner'),
    //保存轮播图
    USER_saveBanner: createAction(request, '/admin/config/saveBanner'),
    //条形码列表
    barcode_barcodeList: createAction(request, '/admin/barcode/barcodeList'),
    barcode_batchData: createAction(request, '/admin/barcode/batchData'),
    barcode_Del_barcodeList: createAction(request, '/admin/barcode/deleteBatch'),
    //删除条形码
    barcode_deleteBarcode: createAction(request, '/admin/barcode/deleteBarcode'),
    //批次列表
    barcode_batchList: createAction(request, '/admin/barcode/batchList'),
    //添加批次
    barcode_saveBatch: createAction(request, '/admin/barcode/saveBatch'),
    //获取用户协议
    config_getUserAgreement: createAction(request, '/admin/config/getUserAgreement'),
    //保存用户协议
    config_saveUserAgreement: createAction(request, '/admin/config/saveUserAgreement'),
    //获取隐私政策
    config_getPrivacyPolicy: createAction(request, '/admin/config/getPrivacyPolicy'),
    //保存隐私政策
    config_savePrivacyPolicy: createAction(request, '/admin/config/savePrivacyPolicy'),
    config_getGuideVideo: createAction(request, '/admin/config/getGuideVideo'),
    config_saveGuideVideo: createAction(request, '/admin/config/saveGuideVideo')
  };
});