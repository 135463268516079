var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.card
    ? _c("el-card", { staticClass: "card-style" }, [
        _c("div", { staticClass: "table-title-bar" }, [
          _vm.title
            ? _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
            : _vm._e(),
          _c("div", { staticClass: "btn-bar" }, [_vm._t("default")], 2),
          _c("div", { staticClass: "extra-box" }, [_vm._t("extra")], 2)
        ])
      ])
    : _c("div", { staticClass: "table-title-bar" }, [
        _vm.title
          ? _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
          : _vm._e(),
        _c("div", { staticClass: "btn-bar" }, [_vm._t("default")], 2),
        _c("div", { staticClass: "extra-box" }, [_vm._t("extra")], 2)
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }