var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-date-picker",
    _vm._g(
      _vm._b(
        {
          attrs: {
            clearable: false,
            type: "monthrange",
            "range-separator": "至",
            "start-placeholder": "请选择开始日期",
            "end-placeholder": "请选择结束日期",
            "value-format": "yyyy-MM"
          },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        "el-date-picker",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }