import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.join";
export default {
  methods: {
    onChangeLocale: function onChangeLocale(command) {
      this.$i18n.locale = command;
      var message = "\u5F53\u524D\u8BED\u8A00\uFF1A".concat(this.$t('_name'), " [ ").concat(this.$i18n.locale, " ]");

      if (process.env.VUE_APP_BUILD_MODE === 'PREVIEW') {
        message = ["\u5F53\u524D\u8BED\u8A00\uFF1A".concat(this.$t('_name'), " [ ").concat(this.$i18n.locale, " ]"), '仅提供切换功能，没有配置具体的语言数据 ', '文档参考：<a class="el-link el-link--primary is-underline" target="_blank" href="https://d2.pub/zh/doc/d2-admin/locales">《国际化 | D2Admin》</a>'].join('<br/>');
      }

      this.$notify({
        title: '语言变更',
        dangerouslyUseHTMLString: true,
        message: message
      });
    }
  }
};