var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-card",
    { attrs: { title: "数据统计" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c(
              "div",
              { staticClass: "box", staticStyle: { background: "#7184fc" } },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("系统销售订单金额")
                ]),
                _c("div", { staticClass: "count" }, [_vm._v("0.00")]),
                _c("div", { staticClass: "num" }, [_vm._v("笔数57")])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c(
              "div",
              { staticClass: "box", staticStyle: { background: "#d06ce7" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("用户充值金额")]),
                _c("div", { staticClass: "count" }, [_vm._v("0.00")]),
                _c("div", { staticClass: "num" }, [_vm._v("笔数0")])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c(
              "div",
              {
                staticClass: "box d2-mt-20",
                staticStyle: { background: "#fb8b56" }
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("div", [_vm._v("市场转售")]),
                  _c("div", { staticStyle: { width: "50%" } }, [
                    _c("span", [_vm._v("交易手续费")]),
                    _c("span", { staticClass: "d2-pl-15" }, [_vm._v("2.78")])
                  ])
                ]),
                _c("div", { staticClass: "count" }, [_vm._v("6301.00")]),
                _c("div", { staticClass: "num" }, [_vm._v("笔数10")])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c(
              "div",
              {
                staticClass: "box d2-mt-20",
                staticStyle: { background: "#4db1fe" }
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("div", [_vm._v("用户提现金额")]),
                  _c("div", { staticStyle: { width: "50%" } }, [
                    _c("span", [_vm._v("提现手续费")]),
                    _c("span", { staticClass: "d2-pl-15" }, [_vm._v("2.78")])
                  ])
                ]),
                _c("div", { staticClass: "count" }, [_vm._v("430.00")]),
                _c("div", { staticClass: "num" }, [_vm._v("笔数7")])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 24, offset: 0 } }, [
            _c(
              "div",
              { staticClass: "d2-mt-15 d2-pt-20" },
              [
                _c("ve-histogram", {
                  attrs: { data: _vm.chartData, extend: _vm.chartExtend }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }