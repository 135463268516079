var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-card", { attrs: { title: "用户统计" } }, [
    _c("div", { staticClass: "d2-flex" }, [
      _c(
        "div",
        { staticStyle: { "min-width": "50%" } },
        [
          _c("ve-ring", {
            attrs: {
              height: "200px",
              data: _vm.chartData,
              extend: _vm.chartExtend,
              settings: _vm.chartSettings
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("注册总人数")]),
          _c("span", [_vm._v("714")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("当日新增注册")]),
          _c("span", [_vm._v("1")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("实名认证")]),
          _c("span", [_vm._v("537")])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }