import "core-js/modules/es.array.concat";
//
//
//
//
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
  name: 'd2-quill-text',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {
      Quill: undefined,
      currentValue: '',
      options: {
        theme: 'snow',
        bounds: document.body,
        debug: 'warn',
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{
            list: 'ordered'
          }, {
            list: 'bullet'
          }], // [{ 'script': 'sub' }, { 'script': 'super' }],
          // [{ 'indent': '-1' }, { 'indent': '+1' }],
          // [{ 'direction': 'rtl' }],
          [{
            size: ['small', false, 'large', 'huge']
          }], [{
            header: [1, 2, 3, 4, 5, 6, false]
          }], [{
            color: []
          }, {
            background: []
          }], // [{ 'font': [] }],
          [{
            align: []
          }], ['clean'], ['link']]
        },
        placeholder: '书写你的内容',
        readOnly: false
      }
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        // 确认是新的值
        if (val !== this.currentValue) {
          this.currentValue = val; // 尝试更新

          if (this.Quill) {
            this.Quill.pasteHTML(this.value);
          }
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    //设置流体高度
    setAutoHeight: function setAutoHeight() {
      var dom = document.getElementsByClassName('ql-editor')[0]; // const winHeight = window.innerHeight;
      // const { top } = dom.getBoundingClientRect();
      // const autoHeight = Math.abs(winHeight - top - 200).toFixed(0);

      var autoHeight = 300;
      dom.style = "max-height:".concat(autoHeight, "px;min-height:").concat(autoHeight, "px");
      this.$forceUpdate();
    },
    init: function init() {
      var _this = this;

      var editor = this.$refs.editor; // 初始化编辑器

      this.Quill = new Quill(editor, this.options); // 默认值

      this.Quill.pasteHTML(this.currentValue); // 绑定事件

      this.Quill.on('text-change', function (delta, oldDelta, source) {
        var html = _this.$refs.editor.children[0].innerHTML;

        var text = _this.Quill.getText();

        var quill = _this.Quill; // 更新内部的值

        _this.currentValue = html; // 发出事件 v-model

        _this.$emit('input', html); // 发出事件


        _this.$emit('change', {
          html: html,
          text: text,
          quill: quill
        });
      }); // 将一些 quill 自带的事件传递出去

      this.Quill.on('text-change', function (delta, oldDelta, source) {
        _this.$emit('text-change', delta, oldDelta, source);
      });
      this.Quill.on('selection-change', function (range, oldRange, source) {
        _this.$emit('selection-change', range, oldRange, source);
      });
      this.Quill.on('editor-change', function (eventName) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        _this.$emit.apply(_this, ['editor-change', eventName].concat(args));
      });
    }
  }
};