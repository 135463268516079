import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import d2Container from './d2-container';
import d2Quill from './d2-quill';
import d2QuillText from './d2-quillText';
import d2Card from './d2-card';
import d2PageCard from './d2-page-card';
import d2CountUp from './d2-count-up';
import globalInstall from './utils/globalInstall';
import draggable from 'vuedraggable';
import HevueImgPreview from 'hevue-img-preview'; // 注册全局组件

import BaseDialog from '@/components/global/base-dialog';
import BaseForm from '@/components/global/base-form';
import BaseSelect from '@/components/global/base-select';
import BaseRadioGroup from '@/components/global/base-radio-group';
import BaseTable from '@/components/global/base-table';
import BaseGallery from '@/components/global/base-gallery';
import RangeDate from '@/components/global/range-date';
import RangeMonth from '@/components/global/range-month';
import RangeDatetime from '@/components/global/range-datetime';
import Rangetime from '@/components/global/range-time';
import RangeInput from '@/components/global/range-input';
import BaseUpload from '@/components/global/base-upload';
import CascaderSelect from '@/components/global/cascader-select';
import CaseCheckboxGroup from '@/components/global/base-checkbox-group';
import CitySelect from '@/components/global/city-select';
import ImageUpload from '@/components/global/image-upload';
import FormGrid from '@/components/global/form-grid';
import TableTitleBar from '@/components/global/base-table/TableTitleBar';
import BaseNumber from '@/components/global/base-number';
import BaseTag from '@/components/global/base-tag';
import BaseLayer from '@/components/global/base-layer';
import BaseSubmit from '@/components/global/base-submit';
import BaseImage from '@/components/global/base-image';
import BaseFile from '@/components/global/base-file';
import BaseLoad from '@/components/global/base-loading';
import BaseAvaCard from '@/components/global/base-ava-card'; // 注意 有些组件使用异步加载会有影响

Vue.component('d2-container', d2Container);
Vue.component('d2-card', d2Card);
Vue.component('d2-count-up', d2CountUp);
Vue.component('d2-page-card', d2PageCard);
Vue.component('d2-icon', function () {
  return import('./d2-icon');
});
Vue.component('d2-icon-svg', function () {
  return import('./d2-icon-svg/index.vue');
}); // 图片查看组件

Vue.component('d2-quill', d2Quill); // 图片查看组件

Vue.component('d2-quill-text', d2QuillText); // 图片查看组件

Vue.use(HevueImgPreview);
globalInstall(BaseDialog, 'base-dialog');
globalInstall(BaseForm, 'base-form');
globalInstall(BaseSelect, 'base-select');
globalInstall(BaseRadioGroup, 'base-radio-group');
globalInstall(BaseTable, 'base-table');
globalInstall(BaseGallery, 'base-gallery');
globalInstall(RangeDate, 'range-date');
globalInstall(RangeMonth, 'range-month');
globalInstall(RangeDatetime, 'range-datetime');
globalInstall(Rangetime, 'range-time');
globalInstall(RangeInput, 'range-input');
globalInstall(BaseUpload, 'base-upload');
globalInstall(CascaderSelect, 'cascader-select');
globalInstall(CaseCheckboxGroup, 'base-checkbox-group');
globalInstall(CitySelect, 'city-select');
globalInstall(FormGrid, 'form-grid');
globalInstall(ImageUpload, 'image-upload');
globalInstall(draggable, 'draggable');
globalInstall(TableTitleBar, 'TableTitleBar');
globalInstall(BaseNumber, 'BaseNumber');
globalInstall(BaseTag, 'BaseTag');
globalInstall(BaseLayer, 'BaseLayer');
globalInstall(BaseSubmit, 'BaseSubmit');
globalInstall(BaseImage, 'BaseImage');
globalInstall(BaseFile, 'BaseFile');
globalInstall(BaseLoad, 'BaseLoad');
globalInstall(BaseAvaCard, 'BaseAvaCard');