import _objectSpread from "D:/mzkj/\u98DF\u7269/food-pc/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";

/**
 * @description 创建一个请求
 */
export var createAction = function createAction(request, url, requestConfig) {
  return function (data) {
    return request(_objectSpread({
      url: url,
      data: data
    }, requestConfig));
  };
};