var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "d2-page-card" },
        [
          _c(
            "d2-card",
            { attrs: { title: _vm.title } },
            [
              _c(
                "div",
                { attrs: { slot: "button" }, slot: "button" },
                [
                  _vm.buttons.includes("save")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("success")
                            }
                          }
                        },
                        [_vm._v("保存 ")]
                      )
                    : _vm._e(),
                  _vm.buttons.includes("close")
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("update:visible", false)
                            }
                          }
                        },
                        [_vm._v("关闭 ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._t("default")
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }