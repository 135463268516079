import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
//校验
var rules = {
  //校验数值
  isNumber: function isNumber() {
    var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    // 0 正整数
    var reg = [/^[0-9]*$/, /^[0-9]*[.][0-9]*$/, /^\d+(\.\d{1,2})?$/, /^\d+(\.\d{1,3})?$/];
    return [{
      required: required,
      message: '请输入数值',
      trigger: 'blur'
    }, {
      pattern: reg[num],
      message: '请输入正确的数值',
      trigger: 'blur'
    }];
  },
  //校验手机号
  isPhone: function isPhone() {
    var required = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    return [{
      required: required,
      message: '请输入手机号',
      trigger: 'blur'
    }, {
      pattern: /^1[3456789]\d{9}$/,
      message: '请输入正确的手机号',
      trigger: 'blur'
    }];
  },
  //校验富文本是否为空
  isRichText: function isRichText() {
    var required = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    //判断富文本正则，有文字，有img标签属于不为空。
    // 判断富文本编辑器输入是否为空或回车
    var getText = function getText(str) {
      return str.replace(/<[^<p>]+>/g, '') // 将所有<p>标签 replace ''
      .replace(/<[</p>$]+>/g, '') // 将所有</p>标签 replace ''
      .replace(/&nbsp;/gi, '') // 将所有 空格 replace ''
      .replace(/<[^<br/>]+>/g, ''); // 将所有 换行符 replace ''
    };

    var isNull = function isNull(str) {
      if (str == '') return true;
      var regu = '^[ ]+$';
      var re = new RegExp(regu);
      return re.test(str);
    };

    var checkContent = function checkContent(rule, value, callback) {
      var text = getText(value);

      if (isNull(text)) {
        callback(new Error('请输入内容'));
      } else {
        callback();
      }
    };

    return [{
      required: required,
      message: '请输入内容',
      trigger: ['blur', 'change']
    }, {
      validator: checkContent,
      trigger: ['blur', 'change']
    }];
  },
  //校验编号需要是指定位数的字符串
  isCode: function isCode() {
    var required = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return [{
      required: required,
      message: '请输入编号',
      trigger: 'blur'
    }, {
      pattern: new RegExp("^[a-zA-Z0-9]{".concat(num, "}$")),
      message: '请输入正确的编号',
      trigger: 'blur'
    }];
  }
};
export default rules;