var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-image",
    {
      style: { width: _vm.width + "px" },
      attrs: {
        src: _vm.src,
        fit: "cover",
        lazy: true,
        "preview-src-list": [_vm.src]
      }
    },
    [
      _c("div", { attrs: { slot: "error" }, slot: "error" }, [
        _c("img", {
          style: { width: _vm.width + "px" },
          attrs: { src: _vm.defaultAvatar, alt: "" }
        })
      ]),
      _c("div", { attrs: { slot: "placeholder" }, slot: "placeholder" }, [
        _c("img", {
          style: { width: _vm.width + "px" },
          attrs: { src: _vm.defaultAvatar, alt: "" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }